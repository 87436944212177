import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";

import { Button, Icon, IconSearch, Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	ContentSection,
	HeaderSection,
	InlineMessage,
	ImportExample,
	Header,
	Code,
	DocsTable,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Select } from "../select/select";
import { InputField, InputFieldWithSlug } from "./input-field";

export const Meta: DocPageMeta = {
	category: "Forms and input",
	title: "Input Field",
	notepad: "https://hackmd.io/F1WKDXwDQHGGxxX17aOkGw",
};

export default (): JSX.Element => (
	<>
		<HeaderSection title="Input Field" subTitle="Enable users to input a single line of text." />
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={InputField} />
				<Header.H2>Input Field v.s Text area</Header.H2>
				<Paragraph>
					To clarify the differences between <Code>InputField</Code> and <Code>TextArea</Code>, a
					short description is provided.
				</Paragraph>
				<DocsTable>
					<thead>
						<tr>
							<th>Component name</th>
							<th>Usage</th>
							<th>Best practice</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Input Field</td>
							<td>Capture short, single-line text (max. 1-2 words)</td>
							<td>Use for names, email addresses, phone numbers</td>
						</tr>
						<tr>
							<td>Text Area</td>
							<td>Capture longer, multi-line text</td>
							<td>Use for paragraphs, feedback, descriptions, comments</td>
						</tr>
					</tbody>
				</DocsTable>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					<b>Composition</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Label</b>: Provides a clear description of the expected input. See{" "}
							<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
								Form Element Wrapper
							</GatsbyLink>{" "}
							.
						</>,
						<>
							<b>Input</b>: The actual text field where users enter data.
						</>,
						<>
							<b>Placeholder</b> (optional): Offers a hint or example of the expected input format.
						</>,
						<>
							<b>Validation/Error Message</b> (optional): Displays feedback on input validity.
						</>,
						<>
							<b>Icon</b> (optional): Enhances visual appeal or provides additional contexts.
						</>,
					]}
				/>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					The default input field is the most common variant, suitable for general text input. It
					adheres to a standard width and height, making it ideal for forms and data-entry tasks.
				</Paragraph>

				<Example fn={DefaultExample} />
				<Header.H3>Large</Header.H3>
				<Paragraph>
					The large input field offers a more prominent visual presence, emphasizing its importance
					or accommodating longer input.
				</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						"Consider using the large variant sparingly to avoid overwhelming the user interface.",
						"Ensure that the input field's width remains proportionate to the surrounding content.",
					]}
				/>
				<Example fn={LargeExample} />
				<Header.H3>Full width</Header.H3>
				<Paragraph>
					The full-width input field spans the entire container width, providing maximum space for
					text input.
				</Paragraph>
				<Paragraph>
					<b>Use Cases</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>
							Input fields within dialogs or{" "}
							<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink>
						</>,
						"Standalone input fields requiring significant visual emphasis",
					]}
				/>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						"Reserve full-width input fields for specific scenarios where their size is justified.",
						"Ensure the input field adapts gracefully to different screen sizes.",
					]}
				/>
				<Example fn={FullWidthExample} />
				<Header.H3>Disabled</Header.H3>
				<Paragraph>
					The disabled input field is visually distinct, indicating that it is not available for
					interaction.
				</Paragraph>
				<Paragraph>
					<b>Use Cases</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						"Displaying read-only information (e.g., system-generated values)",
						"Preventing input in specific contexts (e.g., when a condition is not met)",
					]}
				/>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						"Clearly communicate the reason for the disabled state using a tooltip or accompanying text.",
						"Avoid using disabled input fields for purely aesthetic purposes.",
					]}
				/>
				<Example fn={DisabledExample} />
				<Header.H3>Invalid</Header.H3>
				<Paragraph>
					The invalid input field highlights incorrect or incomplete data, providing immediate
					feedback to the user.
				</Paragraph>
				<Paragraph>
					<b>Use Cases</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>Form validation errors (e.g., incorrect email format, required field missing).</>,
						"Real-time input validation during data entry",
					]}
				/>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>
							Display a clear and concise error message explaining the issue. See{" "}
							<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
								Form Element Wrapper
							</GatsbyLink>{" "}
						</>,
						"Allow users to easily correct the input and clear the error.",
					]}
				/>
				<Example fn={InvalidExample} />
				<Header.H3>Slugs</Header.H3>
				<Paragraph>
					Slug input fields often incorporate symbols like "%" or currency symbols ($) to provide
					visual cues to the user about the expected input format.
				</Paragraph>
				<Example fn={SlugsExample} />
				<Header.H3>Complex Slugs</Header.H3>
				<Paragraph>
					Complex slug input fields provide additional functionality beyond basic text input, such
					as:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Search icon only button</b>: Initiates a search based on the entered text.
						</>,
						<>
							<b>Select as a filter</b>: Allows users to select the input value as a filter to
							refine search results or other data sets.
						</>,
					]}
				/>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>
							Present the functionality of the complex slug clearly and intuitively. See{" "}
							<GatsbyLink to="/lab/components/Forms and input/Auto Complete">
								Auto Complete
							</GatsbyLink>
							.
						</>,
						"Use appropriate icons or labels to indicate the available actions.",
						<>
							Provide visual feedback when actions are performed (e.g., loading state during
							search).
						</>,
						"Ensure that the complex slug behavior is consistent with the overall user experience.",
					]}
				/>
				<Example fn={ComplexSlugExample} />
				<Header.H3>Usage with date</Header.H3>
				<Paragraph>
					When using the <Code>type="date"</Code> property, the input will be rendered with a date
					picker, using the browser's native implementation. This means that the input can be
					rendered differently depending on the browser. The input value will be a string in the
					format <Code>YYYY-MM-DD</Code>, which is the default format for the native date picker,
					but the date presentation will be according to the browser's locale setting.
				</Paragraph>
				<Paragraph>
					<b>Use Cases</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						"Forms requiring date input (e.g., birth date, event date).",
						"Calendars or scheduling features.",
						"Filtering data by date.",
					]}
				/>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						"Use a clear label to indicate the expected date format (e.g., YYYY-MM-DD).",
						"Consider providing additional input validation to ensure the entered date is valid.",
					]}
				/>
				<Example fn={DateExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={InputField}
					initialProps={({ setState }) => ({
						placeholder: "Placeholder text",
						value: "value",
						onChange: (v) => setState({ value: v }),
					})}
				/>

				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>InputField</Code> when
					</Paragraph>
					<Ul
						items={[
							"You need to collect a single line of text input from the user.",
							"The input can be of various types, including text, email, number, date, etc.",
							"You require a simple and standardized way to gather user information.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>InputField</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lab/components/Forms and input/Form">Form</GatsbyLink>: For
								collecting user data, such as names, email addresses, phone numbers, etc.
							</>,
							<>Search: To allow users to enter search queries.</>,
							<>Data entry tables: For inline editing of cell values.</>,
							<>
								<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink>: To gather input
								within a focused context.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>InputField</Code> to existing components for visual
								consistency.
							</>,
							<>
								<b>Responsive Field Widths</b>:
								<Ul
									items={[
										"Field widths should be proportionate to the expected content length, typically allowing for the average input to fit comfortably. Align field widths with the responsive grid system (columns or percentages) to maintain layout consistency.",
										"On smaller screens, prioritize usability by using full-width fields. This prevents users from having to scroll horizontally to input information.",
									]}
								/>
							</>,
							<>
								<b>Clear Labels & Placeholders</b>:
								<Ul
									items={[
										"Labels: Use clear, concise labels placed above the field to identify its purpose. Ensure sufficient contrast between the label and the background for readability.",
										"Placeholders (optional): Use placeholders within the field to provide hints or examples of the expected input format.",
									]}
								/>
							</>,
							<>
								<b>Input Masking & Validation</b>:
								<Ul
									items={[
										<>
											Input masking: For specific formats (e.g., phone numbers,{" "}
											<a href="https://fancy.siteimprove.com/lab/components/Forms%20and%20input/Input%20Field#usage-with-date">
												{"usage with dates"}
											</a>
											), use input masking to guide users and ensure correct input.
										</>,
										<>
											Real-time validation: Provide immediate feedback on input validity (e.g.,
											error messages) to help users correct mistakes quickly. See{" "}
											<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
												Form Element Wrapper
											</GatsbyLink>
											.
										</>,
									]}
								/>
							</>,
							<>
								<b>Graceful Text Handling</b>:
								<Ul
									items={[
										"Input overflow: If the input exceeds the visible field length, either allow horizontal scrolling or use an ellipsis (...) to indicate that there is more content.",
										"Label wrapping: If a label is too long to fit on a single line, allow it to wrap naturally to the next line to maintain readability.",
									]}
								/>
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								You need to collect multi-line text input (use a{" "}
								<GatsbyLink to="/lab/components/Forms and input/Text area">Text area</GatsbyLink>{" "}
								instead).
							</>,
							<>
								You need to collect a file upload (use a{" "}
								<GatsbyLink to="/lab/components/Forms and input/File Input">File Input</GatsbyLink>{" "}
								instead).
							</>,
							<>
								You need a more complex input interaction (use a{" "}
								<GatsbyLink to="/lab/components/Forms and input/Form">Form</GatsbyLink>) instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={["Ensure sufficient color contrast between the input field and its background."]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Use clear, concise labels to indicate the expected input type.",
							"Provide helpful placeholder text as a hint or example.",
							"Avoid using placeholders as substitutes for labels.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const DefaultExample = () => {
	const [value, setValue] = useState("");
	return (
		<InputField
			aria-label="My accessible input component"
			placeholder="Placeholder"
			name="some input"
			value={value}
			onChange={setValue}
		/>
	);
};

const LargeExample = () => {
	const [value, setValue] = useState("");

	return (
		<InputField
			aria-label="My accessible input component"
			placeholder="Large"
			name="some input"
			value={value}
			onChange={setValue}
			large
		/>
	);
};

const FullWidthExample = () => {
	const [value, setValue] = useState("");

	return (
		<InputField
			aria-label="My accessible input component"
			placeholder="Full-width"
			name="some input"
			value={value}
			onChange={setValue}
			fullWidth
		/>
	);
};

const DisabledExample = () => {
	const [value, setValue] = useState("");

	return (
		<InputField
			aria-label="My accessible input component"
			placeholder="Disabled"
			name="some input"
			value={value}
			onChange={setValue}
			disabled
		/>
	);
};

const InvalidExample = () => {
	const [value, setValue] = useState("");
	return (
		<InputField
			aria-label="My accessible input component"
			placeholder="Placeholder"
			name="some input"
			value={value}
			onChange={setValue}
			invalid
		/>
	);
};

const SlugsExample = () => {
	const [value1, setValue1] = useState("");
	const [value2, setValue2] = useState("");
	const [value3, setValue3] = useState("");
	const [value4, setValue4] = useState("");
	return (
		<>
			<InputFieldWithSlug
				aria-label="My accessible input component"
				placeholder="Placeholder"
				name="some input"
				value={value1}
				onChange={setValue1}
				leftSlug="%"
				fullWidth
			/>
			<br />
			<InputFieldWithSlug
				aria-label="My accessible input component"
				placeholder="Placeholder"
				name="some input"
				value={value2}
				onChange={setValue2}
				leftSlug="Count:"
				rightSlug="items"
				fullWidth
			/>
			<br />
			<InputFieldWithSlug
				aria-label="My accessible input component"
				placeholder="Placeholder"
				name="some input"
				value={value3}
				onChange={setValue3}
				rightSlug="$"
				disabled
				fullWidth
			/>
			<br />
			<InputFieldWithSlug
				aria-label="My accessible input component"
				placeholder="Placeholder"
				name="some input"
				value={value4}
				onChange={setValue4}
				rightSlug="$"
				invalid
				fullWidth
			/>
		</>
	);
};

const ComplexSlugExample = () => {
	const [value1, setValue1] = useState("");
	const [value2, setValue2] = useState("");
	const [value3, setValue3] = useState("");

	const dropdownItens = ["Option 1", "Option 2", "Option 3"];
	const [selected, setSelected] = useState<string | undefined>(dropdownItens[0]);

	return (
		<>
			<InputFieldWithSlug
				aria-label="My accessible input component"
				placeholder="Placeholder"
				name="some input"
				value={value1}
				onChange={setValue1}
				leftSlug={<Button>Slug Button!</Button>}
			/>
			<br />
			<InputFieldWithSlug
				aria-label="My accessible input component"
				placeholder="Placeholder"
				name="some input"
				value={value2}
				onChange={setValue2}
				rightSlug={
					<Button aria-label="Search">
						<Icon>
							<IconSearch />
						</Icon>
					</Button>
				}
			/>
			<br />
			<InputFieldWithSlug
				aria-label="My accessible input component"
				placeholder="Placeholder"
				name="some input"
				value={value3}
				onChange={setValue3}
				leftSlug={
					<Select
						items={dropdownItens.map((x) => ({ value: x, title: x }))}
						value={selected}
						onChange={setSelected}
						hideClearButton
						aria-label="list of 3 options"
					/>
				}
				rightSlug={
					<Button aria-label="Search">
						<Icon>
							<IconSearch />
						</Icon>
					</Button>
				}
			/>
		</>
	);
};

const DateExample = () => {
	const [value, setValue] = useState("2023-12-31");
	return (
		<InputField
			aria-label="My accessible date input"
			name="mydate"
			type="date"
			value={value}
			onChange={setValue}
		/>
	);
};
